import { Skeleton, Stack } from "@mui/material";
import GamesCellSkeleton from "./GamesCellSkeleton";

export default function FeedGamesSkeleton() {
  return (
    <Stack m={2}>
      <Skeleton width={150} height={20} sx={{ mb: 2 }} />
      <Stack flexDirection="row" flexWrap="wrap" gap={{ xs: 5, sm: 1.5 }}>
        {[...Array(6)].map(() => (
          <GamesCellSkeleton key={Math.random()} />
        ))}
      </Stack>
    </Stack>
  );
}
