import { useMemo } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { fullPage, pageWithNav } from "../config/routes";

import Layout from "../components/layout/Layout";
import PageNotFound from "../pages/PageNotFound";
import ScrollToTop from "../components/layout/ScrollToTop";
import RequireAuth from "../components/RequireAuth";

export default function useRouter() {
  const pageWithNavRoutes = useMemo(
    () => (
      <Route path="/category" element={<Layout />}>
        {pageWithNav.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            index={!!route?.index}
            loader={route.loader}
            element={
              route?.protected ? (
                <RequireAuth>{route.element}</RequireAuth>
              ) : (
                route.element
              )
            }
          />
        ))}
      </Route>
    ),
    []
  );

  const fullPageRoutes = useMemo(
    () =>
      fullPage.map((route) => (
        <Route
          key={route.id}
          path={route.path}
          index={!!route?.index}
          element={route.element}
          loader={route.loader}
        />
      )),
    []
  );

  const router = useMemo(() => {
    return createBrowserRouter(
      createRoutesFromElements(
        <>
          <Route
            path="/"
            element={<ScrollToTop />}
            errorElement={<p>Error loading.</p>}
          >
            {fullPageRoutes}
            {pageWithNavRoutes}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </>
      )
    );
  }, [fullPageRoutes, pageWithNavRoutes]);

  return router;
}
