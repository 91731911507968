import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const filteringOptions = [
  { value: "0", label: "الكل" },
  { value: "2", label: "أحدث الالعاب" },
  { value: "1", label: "الأكثر لعبًا" },
];

export default function FilteringChips({ active, setActive, loading }) {
  const handleClick = (e) => {
    setActive(+e.target.id);
  };
  return (
    <Stack
      flexDirection="row"
      justifyContent="flex-start"
      gap={{ xs: 0.7, sm: 1.5 }}
      boxShadow={0}
      overflow="scroll"
      // pt={3}
      px={1}
    >
      {filteringOptions.map((filter) => {
        const isActive = active === +filter.value;
        return (
          <LoadingButton
            id={filter.value}
            key={filter.value}
            size="small"
            variant="contained"
            color={isActive && !loading ? "primary" : "lightgrey"}
            loadingPosition="start"
            loading={loading && isActive}
            onClick={handleClick}
            sx={{
              borderRadius: "3rem",
              px: 2,
              py: 0.3,
              paddingInlineStart: loading && isActive ? 5 : 2,
              color: "black",
              whiteSpace: "nowrap",
              minWidth: "min-content",
              minHeight: "min-content",
            }}
          >
            {filter.label}
          </LoadingButton>
        );
      })}
    </Stack>
  );
}
