import { toast } from "react-toastify";
import useAuth from "./useAuth";
import axiosInstance from "../config/axios";

export const endpoints = {
  subscribe: "http://sudanipay.com/subscription/tasalee/tasalee/",
  unsubscribe: "unsubscribe",
  categories: "category",
};

export const login = async (phoneNumber) => {
  try {
    const response = await axiosInstance.post("auth", { phoneNumber });
    return response?.data;
  } catch (error) {
    window.location.assign(endpoints.subscribe);
  }
  return null;
};

export const getCategories = async () => {
  try {
    const response = await axiosInstance.get(endpoints.categories);
    return response.data;
  } catch (error) {}
  return null;
};

export const search = async (searchTerm) => {
  try {
    const url = `search?text=${searchTerm}`;
    const response = await axiosInstance.get(url);
    return { title: searchTerm, contents: response.data };
  } catch (error) {}
  return null;
};

export const getCategoryGames = async (categoryId, limit, activeFilter) => {
  try {
    let url = `category/${categoryId}/content`;
    if (limit) {
      url = url + "?limit=" + limit;
    }
    if (activeFilter) {
      url = url + "&order=" + activeFilter;
    }

    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {}
  return null;
};

export const getGame = async (gameId, categoryId) => {
  try {
    const response = await axiosInstance.get(
      `category/${categoryId}/game/${gameId}`
    );
    return response.data;
  } catch (error) {}
  return null;
};

export default function useApi() {
  const { logout, phoneNumber } = useAuth();

  const cancelSubscription = async () => {
    try {
      const response = await axiosInstance.post(endpoints.unsubscribe, {
        phoneNumber,
      });
      if (response?.data?.success) {
        toast.success("تم إلغاء الاشتراك بنجاح");
        logout();
      }
    } catch (error) {}
  };

  return { cancelSubscription };
}
