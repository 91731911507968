import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import Loading from "./components/Loading";
import useAxios from "./hooks/useAxios";
import useRouter from "./hooks/useRouter";

function App() {
  const router = useRouter();
  useAxios();
  return (
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
