import { Box, Stack, Typography } from "@mui/material";
import src from "../assets/images/sudani_logo.png";

export default function Footer() {
  return (
    <Stack bgcolor={(theme) => theme.palette.background.light} px={1} py={2}>
      <Stack
        flexDirection="column"
        alignItems="center"
        maxWidth="80em"
        mx="auto"
        gap={1}
      >
        <Box height={{ xs: "5rem", sm: "6rem" }} component="img" src={src} />
        <Typography variant="caption">
          قيمة الاشتراك تجدد تلقائيا عبر شبكة سوداني
        </Typography>
      </Stack>
    </Stack>
  );
}
