import { AppBar, Stack, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { path } from "../../config/routes";
import AuthSubButtons from "../AuthSubButtons";
import MenuButton from "./MenuButton";
import Searchbar from "./Searchbar";

export default function Appbar() {
  const navigate = useNavigate();

  const redirectHome = () => {
    navigate(path.home);
  };

  return (
    <AppBar color="transparent" component="nav" position="static">
      <Toolbar>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={5}
          width="100%"
          mx={{ sx: 1, md: 4 }}
          mt={2}
          pb={{ xs: 2, md: 3 }}
          borderBottom={0.001}
          borderColor={(theme) => theme.palette.lightprimary.main}
        >
          <Stack flexDirection="row" alignItems="center">
            <MenuButton />
            <Typography
              color="primary"
              variant="h4"
              fontWeight={700}
              onClick={redirectHome}
              ml={1}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              تسالي
            </Typography>
          </Stack>

          <Stack display={{ xs: "none", md: "flex" }} flexGrow={1}>
            <Searchbar sx={{ width: "50%", m: "auto" }} />
          </Stack>

          <AuthSubButtons
            stackProps={{
              flexDirection: "row",
              gap: 3,
              display: { xs: "none", md: "flex" },
            }}
          />

          {/* <Stack
            flexDirection="row"
            gap={3}
            display={{ xs: "none", md: "flex" }}
          >
            {token ? (
              <>
                <Button variant="contained" onClick={cancelSubscription}>
                  إلغاء الاشتراك
                </Button>
                <Button onClick={logout}>تسجيل الخروج</Button>
              </>
            ) : (
              <>
                <Button variant="contained" href={endpoints.subscribe}>
                  الإشتراك
                </Button>
                <Button onClick={navigateLogin}>تسجيل الدخول</Button>
              </>
            )}
          </Stack> */}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
