import { Stack, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import src from "../assets/images/404Image.png";

export default function PageNotFound() {
  return (
    <Stack my={5} gap={2} alignItems="center">
      <img
        src={src}
        alt=""
        style={{
          height: "50%",
          maxHeight: "400px",
          width: "auto",
          maxWidth: "100vw",
          objectFit: "contain",
        }}
      />
      <Typography variant="h5">
        عذرا لم نتمكن من العثور على الصفحة التي تبحث عنها
      </Typography>
      <Link component={RouterLink} to="/">
        انقر هنا للعودة إلى الصفحة الرئيسية
      </Link>
    </Stack>
  );
}
