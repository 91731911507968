import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css";
import "./assets/css/fonts.css";

import "./assets/fonts/GOArabicFont/NeoSansArabicRegular.ttf";
import "./assets/fonts/GOArabicFont/NeoSansArabicBlack.ttf";
import "./assets/fonts/GOArabicFont/NeoSansArabicBold.ttf";
import "./assets/fonts/GOArabicFont/NeoSansArabicLight.ttf";
import "./assets/fonts/GOArabicFont/NeoSansArabicMedium.ttf";

import ToastContainer from "./components/ToastContainer";
import { ThemeProvderRtl } from "./config/theme";
import { AuthProvider } from "./store/auth-cxt";
import { CatProvidder } from "./store/cat-cxt";
import { CssBaseline } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvderRtl>
      <CatProvidder>
        <AuthProvider>
          <CssBaseline />
          <ToastContainer />
          <App />
        </AuthProvider>
      </CatProvidder>
    </ThemeProvderRtl>
  </React.StrictMode>
);
