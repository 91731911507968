import { Stack } from "@mui/material";
import { Skeleton } from "@mui/material";

export default function GamesCellSkeleton() {
  return (
    <Stack flexDirection="column" my={1}>
      <Skeleton variant="rounded" width={150} height={70} />
      <Skeleton width={150} />
      <Skeleton width={70} />
    </Stack>
  );
}
