import React from "react";

import { loader as gameLoader } from "../pages/Game";
import { loader as feedLoader } from "../pages/Feed";
import Feed from "../pages/Feed";
const Game = React.lazy(() => import("../pages/Game"));
const Home = React.lazy(() => import("../pages/Home"));
const Login = React.lazy(() => import("../pages/Login"));

export const id = {
  home: "home",
  login: "login",
  feed: "feed",
  game: "game",
};

export const path = {
  home: "/",
  login: "/login",
  feed: "/category/:catId",
  game: "/category/:catId/game/:gameId",
};

// routes under layout component -> nav bar at the top
export const pageWithNav = [
  {
    id: id.game,
    path: path.game,
    element: <Game />,
    protected: true,
    loader: gameLoader,
  },
  {
    id: id.feed,
    path: path.feed,
    element: <Feed />,
    loader: feedLoader,
  },
];

export const fullPage = [
  {
    id: id.login,
    path: path.login,
    element: <Login />,
  },
  {
    id: id.home,
    path: path.home,
    index: true,
    element: <Home />,
  },
];
