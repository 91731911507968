import { Grid, Stack, Typography } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import {
  Await,
  defer,
  useLoaderData,
  useParams,
  useSearchParams,
} from "react-router-dom";
import FeedGamesSkeleton from "../components/FeedGamesSkeleton";
import { filteringOptions } from "../components/FilteringChips";
import GameCell from "../components/GameCell";
import { getCategoryGames, search } from "../hooks/useApi";

export default function Feed() {
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const { catId } = useParams();

  const loadedData = useLoaderData();

  useEffect(() => {
    const searchTerm = searchParams.get("search");
    if (search !== searchTerm) {
      window.location.reload();
      setSearch(searchTerm);
    }
  }, [search, searchParams]);

  return (
    <>
      <Suspense fallback={<FeedGamesSkeleton />}>
        <Await resolve={loadedData.categoryGames}>
          {(loadedGames) => (
            <Stack mt={1} gap={1} px={2}>
              <Typography variant="h5">
                {+catId === 0
                  ? filteringOptions.find(
                      (option) => +option.value === +searchParams.get("order")
                    )?.label
                  : loadedGames?.title}
              </Typography>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {loadedGames?.contents &&
                loadedGames?.contents?.length !== 0 ? (
                  loadedGames?.contents.map((game) => (
                    <Grid item key={game?.id} xs={2} md={2}>
                      <GameCell
                        src={game?.thumbnail}
                        gameName={game?.title?.ar}
                        gameId={game?.id}
                        categoryId={game?.category_id}
                        uploadDate={game?.date}
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography m={2}>
                    نأسف لعدم العثور على ما تبحث عنه
                  </Typography>
                )}
              </Grid>
            </Stack>
          )}
        </Await>
      </Suspense>
    </>
  );
}

export async function loader({ request, params }) {
  const param = new URL(request?.url).searchParams;
  const searchTerm = param.get("search");
  const order = param.get("order");

  return defer({
    categoryGames: searchTerm
      ? search(searchTerm)
      : getCategoryGames(params.catId, "0", order),
  });
}
