import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Appbar from "./Appbar";

export default function Layout() {
  return (
    <Stack flexDirection="column" height="100vh">
      <Appbar />
      <Stack
        component="main"
        mx="auto"
        maxWidth="80em"
        width="100%"
        // height="100%"
        flexGrow={1}
        justifyContent="space-between"
        pt={2}
        pb={5}
        px={{ sx: 1, md: 4 }}
        boxSizing="border-box"
      >
        <Outlet />
      </Stack>
      <Footer />
    </Stack>
  );
}
