import {
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { HiMenu } from "react-icons/hi";
import Searchbar from "./Searchbar";
import AuthSubButtons from "../AuthSubButtons";
import { useNavigate } from "react-router-dom";
import { path } from "../../config/routes";
import useCategories from "../../hooks/useCategories";

const drawerWidth = 240;

export default function MenuButton({ window }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const { categories } = useCategories();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? window().document.body : undefined;

  const navigateToCat = (categoryId) => {
    navigate(`/category/${categoryId}`);
    setMobileOpen(false);
  };

  return (
    <>
      <IconButton edge="start" onClick={handleDrawerToggle}>
        <HiMenu color={theme.palette.primary.main} />
      </IconButton>
      <Drawer
        container={container}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: {
            xs: "block",
          },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Stack width="90%" my={5} mx="auto">
          <Typography
            variant="h4"
            color="primary"
            fontWeight={700}
            textAlign="center"
            my={2}
            onClick={() => navigate(path.home)}
          >
            تسالي
          </Typography>
          <Searchbar sx={{ my: 2 }} />
          <Divider
            sx={{
              mt: 2,
              mb: 4,
              borderColor: (theme) => theme.palette.lightprimary.main,
            }}
          />
          <AuthSubButtons stackProps={{ gap: 2 }} />
          <Divider
            sx={{
              my: 4,
              borderColor: (theme) => theme.palette.lightprimary.main,
            }}
          />
          {!!categories.length &&
            categories?.map((cat) => (
              <Typography
                key={cat?.id}
                variant="h6"
                fontWeight={400}
                pl={1}
                sx={{
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={navigateToCat.bind(this, cat?.id)}
              >
                {cat?.name?.ar}
              </Typography>
            ))}
        </Stack>
      </Drawer>
    </>
  );
}
