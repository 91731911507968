import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { path } from "../config/routes";
import useApi, { endpoints } from "../hooks/useApi";
import useAuth from "../hooks/useAuth";

export default function AuthSubButtons({ stackProps }) {
  const navigate = useNavigate();
  const { logout, token } = useAuth();
  const { cancelSubscription } = useApi();

  const navigateLogin = () => {
    navigate(path.login);
  };

  return (
    <Stack {...stackProps} sx={{ "& button": { py: 0.5 } }}>
      {token ? (
        <>
          <Button
            sx={{ flexGrow: 1, whiteSpace: "nowrap" }}
            size="small"
            variant="outlined"
            onClick={cancelSubscription}
          >
            إلغاء الاشتراك
          </Button>
          <Button
            variant="contained"
            size="small"
            // color="btnwhite"
            sx={{
              flexGrow: 1,
              whiteSpace: "nowrap",
              // color: (theme) => theme.palette.primary.main,
            }}
            onClick={logout}
          >
            تسجيل الخروج
          </Button>
        </>
      ) : (
        <>
          <Button
            sx={{ flexGrow: 1, whiteSpace: "nowrap" }}
            variant="contained"
            size="small"
            href={endpoints.subscribe}
          >
            الإشتراك
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{
              flexGrow: 1,
              whiteSpace: "nowrap",
              // color: (theme) => theme.palette.primary.main,
            }}
            onClick={navigateLogin}
          >
            تسجيل الدخول
          </Button>
        </>
      )}
    </Stack>
  );
}
