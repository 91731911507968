import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function RequireAuth({ children }) {
  const { token } = useAuth();
  const location = useLocation();
  return !!token ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
