import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

let theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#008AFF",
    },
    grey: {
      main: "#28333E",
      dark: "#2d2d2d",
    },
    lightgrey: {
      main: "#eeeeee",
      dark: "#bcbcbc",
    },
    lightprimary: {
      main: "#85C6FF",
      light: "#ADD9FF",
    },
    background: {
      default: "#001427",
      light: "#142637",
    },
  },
});

theme = createTheme({
  ...theme,
  direction: "rtl",
  typography: {
    fontFamily: [
      "neo-sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
        },
        notchedOutline: {
          borderColor: theme.palette.lightprimary.main,
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

const cache = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

export function ThemeProvderRtl({ children }) {
  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
}

export default theme;
