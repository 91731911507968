import { Box, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const formatedDate = (date) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export default function GameCell({
  categoryId,
  gameId,
  src,
  gameName,
  width,
  uploadDate,
}) {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/category/${categoryId}/game/${gameId}`);
  }, [categoryId, gameId, navigate]);

  return (
    <Stack
      width={width}
      minHeight="min-content"
      flexShrink={0}
      gap={1}
      onClick={handleClick}
      my={1}
      sx={{
        transition: "all  ease 750ms",
        ":hover": {
          transform: "scale(1.1)",
        },
      }}
    >
      <Box
        component="img"
        loading="lazy"
        src={src}
        alt=""
        width="100%"
        height={{ xs: "6rem", sm: "8rem" }}
        borderRadius={1.25}
        bgcolor={(theme) => theme.palette.grey.main}
        sx={{ objectFit: "cover" }}
      />
      <Stack gap={0.5}>
        <Typography textAlign="center" variant="body1">
          {gameName}
        </Typography>
        <Typography textAlign="center" variant="caption">
          {uploadDate ? formatedDate(new Date(uploadDate)) : "N/A"}
        </Typography>
      </Stack>
    </Stack>
  );
}
