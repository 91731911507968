import { useEffect } from "react";
import useAuth from "./useAuth";
import axiosInstance from "../config/axios";
import { toast } from "react-toastify";

export default function useAxios() {
  const { token } = useAuth();

  useEffect(() => {
    const errorToast = (message) => {
      toast.error(message, { toastId: "axios" });
    };

    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"] && token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        errorToast(
          error?.response?.data?.message ||
            error?.message ||
            "An error occurred"
        );
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [token]);
}
