import { Box, CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import { getGame } from "../hooks/useApi";
// import useAuth from "../hooks/useAuth";

export default function Game() {
  const loaderData = useLoaderData();
  // const { logout } = useAuth();
  return (
    <Box height="100%" maxWidth="98%">
      <Suspense
        fallback={
          <CircularProgress sx={{ display: "block", mx: "auto", my: 5 }} />
        }
      >
        <Await resolve={loaderData.game}>
          {(loadedgame) => {
            if (loadedgame) {
              return (
                <Box
                  component="iframe"
                  title="game"
                  sandbox="allow-scripts allow-same-origin"
                  src={loadedgame?.url}
                  width="100%"
                  height="100%"
                  minHeight={{ xs: "65vh", sm: "90vh" }}
                />
              );
            }
          }}
        </Await>
      </Suspense>
    </Box>
  );
}

export async function loader({ params }) {
  return defer({ game: getGame(params.gameId, params.catId) });
}
