import { Stack } from "@mui/system";
import { Bars } from "react-loader-spinner";
import theme from "../config/theme";

export default function Loading() {
  return (
    <Stack
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Bars
        height="5rem"
        width="5rem"
        color={theme.palette.primary.main}
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Stack>
  );
}
