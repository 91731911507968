import { createContext, useState } from "react";

export const TOKEN = "TOKEN";
const PHONE = "PHONE";

const AuthContext = createContext({
  token: null,
  phoneNumber: null,
  savePhoneNumber: (phoneNumber) => {},
  saveToken: (token) => {},
  logout: () => {},
});

const retriveData = (key) => {
  const data = localStorage.getItem(key);
  return data ?? null;
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(retriveData(TOKEN));
  const [phoneNumber, setPhoneNumber] = useState(retriveData(PHONE));

  const savePhoneNumber = ({ phoneNumber }) => {
    setPhoneNumber(phoneNumber);
    localStorage.setItem(PHONE, phoneNumber);
  };

  const saveToken = (token) => {
    setToken(token);
    localStorage.setItem(TOKEN, token);
  };

  const logout = () => {
    setPhoneNumber(null);
    setToken(null);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(PHONE);
  };

  const value = {
    token,
    phoneNumber,
    savePhoneNumber,
    saveToken,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
