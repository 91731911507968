import { useEffect } from "react";
import { createContext, useState } from "react";
import { getCategories } from "../hooks/useApi";

const CatContext = createContext({
  categories: [],
  loading: false,
});

export const CatProvidder = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCats = async () => {
      setLoading(true);
      const response = await getCategories();
      response && setCategories(response);
      setLoading(false);
    };
    getCats();
  }, []);

  return (
    <CatContext.Provider value={{ categories, loading }}>
      {children}
    </CatContext.Provider>
  );
};

export default CatContext;
