import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function Searchbar({ sx }) {
  const [value, setValue] = useState("");
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateSearch = () => {
    value && navigate(`/category/0?search=${value}`);
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      navigateSearch();
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      size="small"
      placeholder="البحث"
      value={value}
      variant="outlined"
      color="primary"
      onChange={handleChange}
      onKeyDown={handleSearch}
      sx={{
        ...sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={navigateSearch}>
              <AiOutlineSearch color={theme.palette.lightprimary.main} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
